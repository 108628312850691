section.banner {
    background: url(../../../public/assets/images/banner.png) no-repeat;
    background-size: 100% 100%;
    padding: 190px 0 133px;
    @media (max-width: 991px) {
        padding-top: 100px;
        background-size: cover;
        background-position: top left;
    }
    @media (max-width: 767px) {
        padding: 50px 0 40px;
    }
    .txt {
        color: #fff;
        padding-top: 30px;
    }
    p {
        margin: 20px 0;
    }
    button {
        background: #fff;
        color: #323232;
        font-weight: 700;
        &:hover {
            background: #323232 !important;
            color: #fff !important;
        }
    }
    .col-lg-5 {
        @media (min-width: 992px) {
            width: 39%;
        }
    }
    .img {
        position: relative;
        @media (max-width: 991px) {
            margin-top: 60px;
        }
        &::before {
            content: '';
            position: absolute;
            width: 95%;
            height: 95%;
            background: rgba($color: #07689F, $alpha: 0.5);
            border-radius: 20px;
            right: -20px;
            top: -20px;
        }
        img {
            border-radius: 20px;
            position: relative;
        }
    }
}
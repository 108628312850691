section.whyus {
    padding-bottom: 190px;
    @media (max-width: 1199px) {
        padding-bottom: 160px;
    }
    @media (max-width: 767px) {
        padding-bottom: 70px;
    }
    .row {
        margin-top: 100px;
        @media (max-width: 767px) {
            margin-top: 70px;
        }
    }
    .wrapper {
        max-width: 950px;
        margin: auto;
    }
    .txt {
        p {
            font-size: 22px;
            margin-top: 15px;
            @media (max-width: 767px) {
                font-size: 17px;
                margin-bottom: 40px;
            }
        }
    }
    @media (max-width: 767px) {
        .img {
            img {
                max-width: 219px;
                margin: auto;
                display: block;
            }
        }
        .row4 {
            img {
                max-width: 180px;
            }
        }
    }
    .row1 {
        img {
            max-width: 250px;
            @media (max-width: 767px) {
                max-width: 192px;
            }
        }
    }
    .row2 {
        .txt {
            p {
                max-width: 480px;
            }
        }
    }
}
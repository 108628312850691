* {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

// Importing Fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500&family=Oswald:wght@300;400;500;600;700&display=swap');
body {
    font-family: 'DM Sans', sans-serif;
    color: #323232;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Oswald', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    margin: 0;
    padding: 0;
}

img {
    width: 100%;
    height: auto;
}

h1 {
    font-size: 72px;
    font-weight: 700;
    line-height: 1.1;
    @media (max-width: 991px) {
        font-size: 62px;
    }
    @media (max-width: 767px) {
        font-size: 42px;
    }
}

h2 {
    font-size: 60px;
    font-weight: 700;
    @media (max-width: 991px) {
        font-size: 50px;
    }
    @media (max-width: 767px) {
        font-size: 38px;
    }
}

h3 {
    font-size: 36px;
    font-weight: 700;
    @media (max-width: 991px) {
        font-size: 32px;
    }
}

h4 {
    font-size: 24px;
    font-weight: 700;
}

.subtitle {
    font-size: 28px;
    line-height: 1.4;
    @media (max-width: 991px) {
        font-size: 24px;
    }
    @media (max-width: 767px) {
        font-size: 18px;
    }
    @media (max-width: 400px) {
        font-size: 16px;
    }
}

.btn_site {
    color: #fff;
    background: linear-gradient(to right, #0054A3, #4ABAB9);
    border: none;
    border-radius: 50px;
    font-weight: 500;
    padding: 12px 24px;
    min-width: 170px;
    outline: 0;
    box-shadow: none;
    transition: .3s all ease;
    @media (max-width: 767px) {
        max-width: 150px;
        min-width: unset;
    }
    &:hover {
        background: linear-gradient(to right, #4ABAB9, #0054A3);
        color: #fff !important;
        transition: .3s all ease;
    }
    &:disabled {
        color: #fff;
    }
    &.outlined {
        background: #fff;
        border: 1px solid #0058A6;
        color: #5B5B5B;
        &:hover {
            background: linear-gradient(to right, #4ABAB9, #0054A3);
            color: #fff !important;
            transition: .3s all ease;
        }
    }
}

.btn_txt {
    color: #0058A6;
    font-weight: 500;
    padding: 12px 24px;
    outline: 0;
    box-shadow: none;
    transition: .3s all ease;
}

@import url(header.scss);
@import url(footer.scss);
@import url(banner.scss);
@import url(BetterForYou.scss);
@import url(WhyUs.scss);
@import url(HowItWorks.scss);
@import url(CalculatorSection.scss);
@import url(ServiceBanner.scss);
@import url(MapSection.scss);
@import url(StayCoolTonight.scss);
@import url(Popups.scss);
@import url(MobileScreens.scss);
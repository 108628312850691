header {
    nav {
        background: #fff;
        padding: 24px 0 !important;
        @media (max-width: 767px) {
            padding: 11px 0 !important;
        }
        .navbar-brand {
            max-width: 178px;
            padding: 0;
            margin-bottom: -109px;
            @media (max-width: 1199px) {
                margin-bottom: -13%;
            }
            @media (max-width: 991px) {
                margin-bottom: -17%;
            }
            @media (max-width: 767px) {
                max-width: 100px;
                margin-bottom: -74px;
            }
        }
        &.gradient {
            background: linear-gradient(to right, #0054A3, #4ABAB9);
            padding: 30px 0 !important;
            .navbar-brand {
                max-width: 140px;
                margin-bottom: -96px;
            }
        }
    }
}
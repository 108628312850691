// Booking Popups
.booking_popup {
    padding-right: 0 !important;
    .modal-dialog {
        max-width: 622px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: max-content;
        position: absolute;
        @media (max-width: 767px) {
            margin: auto 15px;
        }
    }
    .modal-content {
        padding: 30px 40px;
        @media (max-width: 767px) {
            padding: 35px 30px;
        }
    }
    .modal-header {
        padding: 0;
        border-bottom: 0;
    }
    .btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .modal-body {
        padding: 0;
        border: none;
        h3 {
            font-size: 32px;
            @media (max-width: 767px) {
                text-align: center;
                font-size: 30px;
            }
        }
        &>p {
            padding: 13px 0px 20px;
            @media (max-width: 767px) {
                text-align: center;
            }
        }
        p {
            font-size: 22px;
            color: #626262;
            line-height: 1.0;
            @media (max-width: 767px) {
                font-size: 18px;
            }
            @media (max-width: 340px) {
                font-size: 16px;
            }
            b {
                color: #323232;
            }
        }
        .row {
            margin-bottom: 22px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .row3 {
            border-top: 1px solid #C9C9C9;
            padding-top: 20px;
        }
        .quantity {
            max-width: 193px;
            display: flex;
            border: 1px solid rgba($color: #000000, $alpha: 0.15);
            border-radius: 5px;
            @media (max-width: 767px) {
                margin: 10px 0 25px;
            }
            button {
                font-size: 25px;
                line-height: 1.0;
                background: rgba($color: #0058A6, $alpha: 0.1);
                color: #0058A6;
                border-radius: 5px;
            }
            input {
                height: 39px;
                text-align: center;
                border: none !important;
                box-shadow: none !important;
                outline: 0 !important;
                width: calc(100% - 78px);
            }
        }
    }
    .modal-footer {
        border: none;
        padding: 25px 0 0;
        button {
            width: 100%;
            max-width: 310px;
        }
    }
    .icon_info {
        display: flex;
        align-items: center;
        position: relative;
        img {
            width: 16px;
            margin-left: 10px;
            &:hover+.delv_info {
                display: block;
            }
        }
        .delv_info {
            border-radius: 10px;
            box-shadow: 0 10px 35px rgba($color: #000000, $alpha: 0.16);
            text-align: center;
            width: 213px;
            padding: 20px 20px;
            background: #fff;
            z-index: 1;
            position: absolute;
            left: 100%;
            transform: translateY(13%);
            display: none;
            h4 {
                margin-bottom: 8px;
            }
            p {
                font-size: 18px;
                color: #323232;
            }
            .portion {
                padding: 7px;
                border-bottom: 0.5px solid #BEBEBE;
                &:last-of-type {
                    border: none;
                }
                p {
                    margin-bottom: 4px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .form-control {
        width: 100%;
        height: 75px;
        font-size: 22px;
        border-radius: 10px;
        color: #9B9B9B;
        border: 1px solid #707070;
        box-shadow: none !important;
        outline: 0 !important;
        margin-top: 25px;
        @media (max-width: 767px) {
            height: 65px;
            font-size: 18px;
        }
    }
    .inpOtp {
        margin-top: 25px;
        input {
            border-radius: 10px;
            border: 1px solid #A8A8A8;
            @media (max-width: 767px) {
                width: 40px !important;
                height: 40px !important;
                margin-right: 9px !important;
            }
        }
    }
    .info {
        p {
            margin-bottom: 8px;
        }
    }
    .units {
        text-align: right;
        @media (max-width: 767px) {
            text-align: center;
            margin-top: 30px;
        }
        h3 {
            font-size: 50px;
            line-height: 1.0;
            color: #398DBA;
            margin: 7px 0;
        }
    }
}
footer {
    margin-top: 90px;
    .wrapper {
        background: url(../../../public/assets/images/footerBg.png) no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .logo {
        margin-top: -90px;
        position: relative;
        img {
            max-width: 178px;
        }
    }
    .bar {
        margin-top: 40px;
        padding: 15px 0;
        border-top: 1px solid rgba($color: #E4E4E4, $alpha: 0.2);
        a {
            color: #F2F2F2;
            font-size: 14px;
            line-height: 1.0;
            text-decoration: none;
            margin-right: 8px;
            padding-right: 8px;
            border-right: 1px solid #F2F2F2;
            &:last-of-type {
                margin-right: 0;
                padding-right: 0;
                border-right: none;
            }
        }
    }
}
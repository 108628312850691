section.better {
    margin-top: 15px;
    padding-bottom: 100px;
    .section-title {
        margin-bottom: 30px;
    }
    // .wrapper {
    //     max-width: 764px;
    //     margin: auto;
    // }
    @media (max-width: 767px) {
        .col-auto {
            width: 100%;
        }
    }
    .vs {
        background: linear-gradient(to right, #56C4C5, #3383B8);
        border-radius: 50%;
        line-height: 1.0;
        width: 62px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 991px) and (min-width: 768px) {
            width: 38px;
            height: 38px;
        }
        @media (max-width: 767px) {
            margin: 15px auto;
        }
        h3 {
            line-height: 1.0;
            font-size: 35px;
            color: #fff;
            @media (max-width: 991px) and (min-width: 768px) {
                font-size: 22px;
            }
        }
    }
}

.shadow_box {
    background: #0058A6;
    border-radius: 20px;
    box-shadow: 0 12px 40px rgba($color: #756E6E, $alpha: 0.2);
    padding: 25px 50px 25px 20px;
    @media (max-width: 991px) {
        padding: 25px 20px;
    }
    h3 {
        margin-bottom: 15px;
        color: #fff;
        font-size: 24px;
    }
    p {
        font-size: 18px;
        color: #fff;
    }
    ul {
        list-style: none;
        li {
            position: relative;
            padding-left: 20px;
            font-size: 18px;
            margin-bottom: 12px;
            color: rgba($color: #fff, $alpha: 0.85);
            &::before {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: rgba($color: #fff, $alpha: 0.85);
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    &.big {
        background: #fff;
        margin: 50px auto 0;
        max-width: 379px;
        text-align: center;
        padding: 25px 35px;
        @media (max-width: 767px) {
            margin-top: 30px;
        }
        h2 {
            color: #0058A6;
            font-family: 'DM Sans', sans-serif;
            letter-spacing: 3px;
            margin: 5px 0;
            sup {
                font-size: 28px;
                top: -0.9em;
                font-family: 'Circular-Loom';
                @media (max-width: 767px) {
                    top: -0.5em;
                }
            }
        }
        p {
            margin-bottom: 8px;
            color: #323232;
        }
        button {
            margin-top: 12px;
            width: 100%;
        }
    }
}
section.cal_section {
    background: url(../../../public/assets/images/cal_bg.png) no-repeat;
    background-size: cover;
    background-position: center center;
    @media (max-width: 991px) {
        padding-top: 40px;
    }
    h2 {
        color: #fff;
    }
    .wrapper {
        max-width: 1050px;
        margin: auto;
    }
}

.calculator {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 12px 40px rgba($color: #756E6E, $alpha: 0.25);
    padding: 25px 30px;
    margin: -21% 0 -26%;
    position: relative;
    @media (max-width: 1199px) {
        margin: -17% 0 -24%;
    }
    @media (max-width: 991px) {
        margin: 35px 0 -24%;
    }
    @media (max-width: 767px) {
        padding: 20px 20px;
        margin-bottom: -57%;
        .col-6 {
            &:nth-of-type(odd) {
                padding-right: 4px;
            }
            &:nth-of-type(even) {
                padding-left: 4px;
            }
        }
        // .col-12 {
        //     padding: 0 12px;
        // }
    }
    .form_group {
        input {
            height: 50px;
            border-color: rgba($color: #000000, $alpha: 0.15);
            color: #848484;
            &:-internal-autofill-selected {
                background: #fff !important;
            }
            @media (max-width: 767px) {
                font-size: 12px;
                height: 33px;
            }
        }
        label {
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        .icon {
            position: absolute;
            top: 0;
            right: 1px;
            bottom: 0;
            height: max-content;
            margin: auto;
            background: #fff;
            padding-right: 10px;
        }
        svg {
            path {
                fill: #848484;
            }
        }
    }
    p {
        font-size: 20px;
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
    .quantity {
        display: flex;
        margin: 17px 0;
        input {
            height: 84px;
            width: 100%;
            border: 1px solid rgba($color: #000000, $alpha: 0.15);
            color: #848484;
            border-radius: 6px;
            text-align: center;
            font-weight: 700;
            font-size: 45px;
            color: #0058A6;
            margin: 0 10px;
            @media (max-width: 767px) {
                margin: 0 5px;
                font-size: 36px;
                height: 65px;
            }
        }
        button {
            font-size: 36px;
            background: rgba($color: #0058A6, $alpha: 0.10) !important;
            border: none;
        }
    }
    .detail {
        margin-bottom: 25px;
        p {
            font-size: 18px;
            line-height: 1.0;
            text-align: right;
            max-width: 55px;
            margin: auto;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        label {
            color: rgba($color: #0058A6, $alpha: 0.6);
            font-size: 18px;
            line-height: 1.0;
            margin: 0;
            text-align: right;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
    .night {
        background: rgba($color: #3D3D3D, $alpha: 0.05);
        border-radius: 5px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 12px;
        p {
            max-width: 100%;
            text-align: center;
        }
    }
    .total {
        border-radius: 5px;
        margin: 4px 0 30px;
        background: rgba($color: #0058A6, $alpha: 0.05);
        padding-top: 13px;
        padding-bottom: 13px;
        p {
            font-weight: 700;
        }
    }
    .footer {
        .col-7 {
            border-right: 0.5px solid #898989;
            width: 55%;
            @media (max-width: 767px) {
                width: 50%;
            }
        }
        .col-5 {
            width: 45%;
            @media (max-width: 767px) {
                width: 50%;
                padding-right: 4px;
            }
        }
        p {
            font-size: 16px;
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
        button {
            width: 100%;
            max-width: unset;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        span {
            font-size: 14px;
            color: #848484;
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
    }
}
section.how_works {
    padding: 230px 0 0;
    @media (max-width: 767px) {
        padding-top: 250px;
    }
    .row {
        margin-top: 50px;
    }
    .wrapper {
        max-width: 1050px;
        margin: 50px auto 0;
    }
    .txt {
        h3 {
            color: #323232;
        }
        p {
            font-size: 22px;
            margin-top: 15px;
            color: #626262;
            @media (max-width: 767px) {
                font-size: 17px;
                margin-bottom: 40px;
            }
        }
    }
    .img {
        img {
            display: block;
            margin: auto;
        }
    }
    @media (max-width: 767px) {
        .row1 {
            img {
                max-width: 350px;
            }
        }
        .row3 {
            img {
                max-width: 195px;
            }
        }
    }
    .row2 {
        .img {
            position: relative;
            @media (max-width: 767px) {
                width: calc(100% - 20px);
                margin-left: auto;
            }
            &::before {
                content: '';
                position: absolute;
                width: 98%;
                height: 98%;
                background: rgba($color: #07689F, $alpha: 0.5);
                border-radius: 20px;
                left: -20px;
                top: -20px;
            }
            img {
                border-radius: 20px;
                position: relative;
            }
        }
    }
}
.mob_screens {
    min-height: calc(100vh - 92px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 35px 15px 15px;
    p {
        font-size: 18px;
        // margin-bottom: 15px;
    }
    h3 {
        font-size: 28px;
        margin: 15px 0;
    }
    .statement {
        margin: 15px auto;
        max-width: 270px;
    }
    .checkMark {
        max-width: 60px;
    }
    .btn {
        display: block;
        margin: 10px auto 0;
        width: 100%;
        max-width: 250px;
    }
    .change {
        font-size: 18px;
        color: #3057F6;
        text-decoration: underline;
        padding: 0;
        line-height: 1.0;
        margin-bottom: 8px;
    }
    .past {
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        padding: 20px 0 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba($color: #707070, $alpha: 0.30);
    }
    .tech_pic {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
}
section.custom_map {
    .map_info {
        background: #fff;
        border-radius: 10px;
        padding: 20px 33px 40px;
        max-width: 405px;
        width: 100%;
        position: absolute;
        right: 22%;
        top: 20%;
        @media (max-width: 991px) {
            right: 3%;
            top: 4%;
            max-width: 350px;
        }
        @media (max-width: 767px) {
            display: none;
        }
        .wrapper {
            max-width: 285px;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin-top: 15px;
            li {
                width: 50%;
                line-height: 1.0;
                position: relative;
                padding-left: 20px;
                margin-top: 15px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: rgba($color: #009E84, $alpha: 0.85);
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
    }
}